import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import nickImage from '../../images/portfolio-back-nick.jpg'
import consortImage from '../../images/portfolio-back-consort.jpg'
import obsessionImage from '../../images/portfolio-back-obsession.jpg'
import ecotectureImage from '../../images/portfolio-back-ecotecture.jpg'
import focusImage from '../../images/portfolio-back-focus.jpg'
import dinamicservicesImage from '../../images/portfolio-back-dinamicservices.jpg'
import bridiefayeartImage from '../../images/portfolio-back-bridiefayeart.jpg'

import projectsStyle from './portfolios.module.scss'
class Projects extends Component {
    render() {
        return ( 
            <div className="page">
                <Helmet>
                    <meta charSet="utf-8"/>
                    <meta name="Description" content="Check out some of my previous projects here and get in touch if you would like a website developed."/>
                    <meta name="viewport" content="width=device-width, initial-scale=1"/>
                    <link rel="canonical" href="https://www.danjbennett.com/Portfolio" />
                    <title>Daniel Bennett - Portfolio</title>
                </Helmet>
                <ul className = { projectsStyle.projects }>
                    <li className={projectsStyle.one}>
                        <a href="/Portfolio/Consortsw1" style={{backgroundImage: "url(" + consortImage + ")"}}>
                            <div className={ projectsStyle.innerBox }>
                                <span className={ projectsStyle.left }>Consort SW1</span> 
                                <span className={ projectsStyle.right }><FontAwesomeIcon icon={['fab', 'react']}></FontAwesomeIcon></span> 
                            </div> 
                        </a> 
                    </li> 
                    <li className={projectsStyle.two} >
                        <a href="/Portfolio/Obsessiondj" style={{backgroundImage: "url(" + obsessionImage + ")"}}>
                            <div className={projectsStyle.innerBox}>
                                <span className={ projectsStyle.left }> Obsession DJ </span> 
                                <span className={ projectsStyle.right }><FontAwesomeIcon icon={['fab', 'php']}></FontAwesomeIcon></span> 
                            </div> 
                        </a> 
                    </li> 
                    <li className={projectsStyle.three}>
                        <a href="/Portfolio/Nicholasburman" style={{backgroundImage: "url(" + nickImage + ")"}}>
                            <div className={ projectsStyle.innerBox }>
                                <span className={ projectsStyle.left }> Nicholas Burman </span> 
                                <span className={ projectsStyle.right }><FontAwesomeIcon icon={['fab', 'angular']}></FontAwesomeIcon></span> 
                            </div> 
                        </a> 
                    </li>
                    <li className={projectsStyle.four}>
                        <a href="/Portfolio/Ecotecture" style={{backgroundImage: "url(" + ecotectureImage + ")"}}>
                            <div className={ projectsStyle.innerBox }>
                                <span className={ projectsStyle.left }> Ecotecture LTD </span> 
                                <span className={ projectsStyle.right }><FontAwesomeIcon icon={['fab', 'react']}></FontAwesomeIcon></span> 
                            </div> 
                        </a> 
                    </li> 
                    <li className={projectsStyle.five}>
                        <a href="/Portfolio/Focusprint" style={{backgroundImage: "url(" + focusImage + ")"}}>
                            <div className={ projectsStyle.innerBox }>
                                <span className={ projectsStyle.left }> Focus Print </span> 
                                <span className={ projectsStyle.right }><FontAwesomeIcon icon={['fab', 'react']}></FontAwesomeIcon></span> 
                            </div> 
                        </a> 
                    </li> 
                    <li className={projectsStyle.six}>
                        <a href="/Portfolio/Dinamicservices" style={{backgroundImage: "url(" + dinamicservicesImage + ")"}}>
                            <div className={ projectsStyle.innerBox }>
                                <span className={ projectsStyle.left }> Dinamic Services </span> 
                                <span className={ projectsStyle.right }><FontAwesomeIcon icon={['fab', 'react']}></FontAwesomeIcon></span> 
                            </div> 
                        </a> 
                    </li> 
                    <li className={projectsStyle.seven}>
                        <a href="/Portfolio/Bridiefayeart" style={{backgroundImage: "url(" + bridiefayeartImage + ")"}}>
                            <div className={ projectsStyle.innerBox }>
                                <span className={ projectsStyle.left }> Bridie Faye Art </span> 
                                <span className={ projectsStyle.right }><FontAwesomeIcon icon={['fab', 'react']}></FontAwesomeIcon></span> 
                            </div> 
                        </a> 
                    </li> 
                </ul> 
            </div>
        );
    }
}

export default Projects;