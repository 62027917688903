import React, { Component } from 'react';
import {Helmet} from 'react-helmet';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import cvStyle from './cv.css'

class cv extends Component {
  render() {
    return (
      <div className="page">
        <Helmet>
          <meta charSet="utf-8"/>
          <meta name="viewport" content="width=device-width, initial-scale=1"/>
          <meta name="robots" content="noindex,nofollow"/>
          <link rel="canonical" href="https://www.danjbennett.com/CV" />
          <title>Daniel Bennett - CV</title>
        </Helmet>
        <p className={cvStyle.intro}>Software engineer with over four years’ experience in the computing industry, currently working at BAE Systems and creating commission-based freelance web development projects. I have a keen interest in design, programming and learning new web-based technologies.</p>
        <h2 className={cvStyle.center}>Technologies</h2>
        <ul className={cvStyle.technologies}>
            <li><FontAwesomeIcon icon={['fab', 'java']}></FontAwesomeIcon>Java</li>
            <li><FontAwesomeIcon icon={['fab', 'microsoft']}></FontAwesomeIcon>C#</li>
            <li><FontAwesomeIcon icon={['fab', 'react']}></FontAwesomeIcon>React JS</li>
            <li><FontAwesomeIcon icon={['fab', 'angular']}></FontAwesomeIcon>Angular</li>
            <li><FontAwesomeIcon icon={['fab', 'google']}></FontAwesomeIcon>GoLang</li>
            <li><FontAwesomeIcon icon={['fab', 'php']}></FontAwesomeIcon>PHP</li>
            <li><FontAwesomeIcon icon={['fab', 'html5']}></FontAwesomeIcon>HTML</li>
            <li><FontAwesomeIcon icon={['fab', 'css3']}></FontAwesomeIcon>CSS</li>
            <li><FontAwesomeIcon icon={['fab', 'sass']}></FontAwesomeIcon>SCSS</li>
            <li><FontAwesomeIcon icon={['fab', 'node-js']}></FontAwesomeIcon>JavaScript</li>
            <li><FontAwesomeIcon icon={['fab', 'js-square']}></FontAwesomeIcon>jQuery</li>
            <li><FontAwesomeIcon icon={['fas', 'terminal']}></FontAwesomeIcon>PowerShell</li>
            <li><FontAwesomeIcon icon={['fas', 'database']}></FontAwesomeIcon>SQL</li>
            <li><FontAwesomeIcon icon={['fas', 'terminal']}></FontAwesomeIcon>Terraform</li>
            <li><FontAwesomeIcon icon={['fab', 'git']}></FontAwesomeIcon>GIT</li>
            <li><FontAwesomeIcon icon={['fab', 'linux']}></FontAwesomeIcon>Linux</li>
            <li><FontAwesomeIcon icon={['fab', 'aws']}></FontAwesomeIcon>AWS</li>
        </ul>
        {/* <span className={cvStyle.divider}></span> */}
        <h1 className={cvStyle.title}>Experience</h1>
        <h2 className={cvStyle.place}>Freelance</h2>
        <span className={cvStyle.period}>2015 - Present</span>
        <p>I am a freelance web developer, using the skills developed through my on-site learning on a range of commissioned projects. My portfolio contains multiple sites created in my own time mainly using React JS, please take a look at the rest of my website for more details on these <Link aria-label="portfolio" to="/Portfolio">projects</Link>.</p>
        <p>These websites are all built using Jenkins and deployed to a Dockerised environment using Ansible.</p>
       {/* <span className={cvStyle.divider}></span> */}
        <h2 className={cvStyle.place}>BAE Systems Applied Intelligence</h2>
        <span className={cvStyle.period}>Jan 2017 – Present</span>
        <p>Developing critical applications, building deployment pipelines, creating and testing prototype software - all following agile SAFe principles.</p>
        <h3>Software Engineer</h3>
        <h4>Second Development Team</h4>
        <ul className={cvStyle.cvList}>
          <li>Developing a set of C# streaming services that use Kafka, MongoDB, AMQP and S3</li>
          <li>Writing frontend JavaScript tests using Cypress</li>
          <li>Contributing features to GoLang applications</li>
        	<li>Creating Terraform and Helm scripts to deploy Kubernetes containers to an AWS OpenShift environment</li>
          <li>Creating and running integration tests on applications in C# and GoLang</li>
          <li>Deploying applications using Bamboo/Jenkins in Docker Swarm</li>
        </ul>

        <h4>First Development Team</h4>
        <ul className={cvStyle.cvList}>
          <li>Developing a Java enrichment micro service framework with Spring technology</li>
        	<li>Creating terraform scripts to deploy architecture to AWS and Azure cloud platforms</li>
          <li>Creating and running performance tests on applications in Java and Python</li>
          <li>Writing Python scripts to integrate with external team’s frameworks</li>
          <li>Developing Java applications to work with oozie pipelines for a Hadoop platform</li>
          <li>Developing pipelines in Bamboo/Jenkins and converting to configuration as code in Java and YAML</li>
          <li>AWS developer training with QA</li>
        </ul>

        <h3>DevOps Engineer</h3>
        <p>Supporting critical applications, focusing on scripting and automating. Experience in SCRUM teams in an agile environment using the Atlassian suite.</p>
        <h4>Responsibilities</h4>
        <ul className={cvStyle.cvList}>
            <li>Keeping data compliant with legal policies</li>
            <li>Analysing large data sources with Oracle SQL</li>
            <li>Windows support – services, scheduled tasks, failover clusters, DFSR, disk management</li>
            <li>Managing GIT repositories </li>
            <li>Creating scripts to automate tasks</li>
            <li>Functional testing for applications</li>
            <li>Out-of-hours work – on-call duties and deploying updates</li>
            <li>Developing and maintaining web-based dashboard over several environments</li>
        </ul>
        <h2 className={cvStyle.place}>QA Training</h2>
        <span className={cvStyle.period}>June 2016 – Dec 2016</span>
        <p>Six-month training course for my role as a software engineer at BAE Systems. Completed modules included:</p>
            <ul className={cvStyle.cvList}>
                <li>Java</li>
                <li>Java EE</li>
                <li>Cyber security</li>
                <li>SQL</li>
                <li>UX</li>
                <li>Linux</li>
                <li>Web Development Skills</li>
                <li>Software Testing </li>
            </ul>
        <p>Qualifications from the course listed below.</p>
        <h2 className={cvStyle.place}>Evosite</h2>
        <span className={cvStyle.period}>October 2014 – March 2016</span>
        <h3>Web Developer</h3>
        <p>Developed large-scale commercial PHP websites for a web development business. Projects included Flogas, Your Move and Deborah Meaden’s personal website. </p>
        <p>Each website was responsive and built around a look and functionality based on in-house designs. I used Linux commands and a task runner to streamline the workflow. </p>
        <h1 className={cvStyle.title}>Education</h1>
        <ul className={cvStyle.education}>
            <li>AWS Certified Developer (2022) – Associate <FontAwesomeIcon icon={['fab', 'aws']}></FontAwesomeIcon></li>
            <li>QA Training (2016) qualifications:
                <ul>
                    <li>Oracle Certified Associate, Java SE 7 Programmer <FontAwesomeIcon icon={['fab', 'java']}></FontAwesomeIcon></li>
                    <li>ISTQB-BCS Certified Tester Foundation Level</li>
                    <li>ITIL Foundation certificate in IT Service Management</li>
                </ul>
            </li>
            <li>Bridgwater College (2012 - 2014) - HND Diploma Level 5 in Computing and System Development – Merit</li>
            <li>Bridgwater College (2010 - 2012) - BTEC Level 3 Extended Diploma in IT - Triple Distinction</li>
            <li>Haygrove Bridgwater (2005 -2010) - 9 GCSE’s Grade A – C</li>
        </ul>
      </div>
    );
  }
}

export default cv;
