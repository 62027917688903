import React, { Component } from 'react';
import qs from 'qs';
import axios from 'axios';
import ReCAPTCHA from 'react-google-recaptcha';

import contactStyle from './contact.module.scss';

//const testSiteKey = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
const liveSiteKey= "6Lf40a0UAAAAADXo1ZXeOT_u5v3Dmg_mZ3plHZr4"
let recaptchaInstance;

class contactForm extends Component {
    constructor() {
        super()

        this.state = {
            name: '',
            nameClass: '',
            nameValid: false,

            email: '',
            emailClass: '',
            emailValid: false,

            subject: '',
            subjectClass: '',
            subjectValid: false,

            message: '',
            messageClass: '',
            messageValid: false,

            showAlertBox: false,
            alertText: '',
            isVerified: false,
            success: false
        }
        this.handleChange = this.handleChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.verifyCallback = this.verifyCallback.bind(this)
    }

    handleChange = e => {
        let inputName = e.target.name;
        this.setState({ [inputName]: e.target.value }, () => this.validateForm(inputName));  
    }

    verifyCallback (response) {
        if (response) {
            this.setState({ isVerified: true })
        }
    }

    validateForm = (formName) => {
        switch(formName) {
            case 'name':
                if (this.state.name) { 
                    this.setState({nameClass: 'valid', nameValid: true});
                } else {
                    this.setState({nameClass: 'invalid', nameValid: false});
                }
                break;
            case 'email':
                if (this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) {  
                    this.setState({emailClass: 'valid', emailValid: true});
                } else {
                    this.setState({emailClass: 'invalid', emailValid: false});
                }
                break;
            case 'subject':
                if (this.state.subject) { 
                    this.setState({subjectClass: 'valid', subjectValid: true});
                } else {
                    this.setState({subjectClass: 'invalid', subjectValid: false});
                }
                break;
            case 'message':
                if (this.state.message) { 
                    this.setState({messageClass: 'valid', messageValid: true});
                } else {
                    this.setState({messageClass: 'invalid', messageValid: false});
                }
                break;
            default:
                break;
        }
    }

    async handleSubmit(e) {
        this.setState({success: false})
        e.preventDefault()
        if (!this.state.isVerified) {
            this.setState({success: false, showAlertBox: true, alertText: 'Please check the reCaptcha box'});
        } else if (!this.state.nameValid) {
            this.setState({success: false, showAlertBox: true, alertText: 'Please enter a valid name'});
        } else if (!this.state.emailValid) {
            this.setState({success: false, showAlertBox: true, alertText: 'Please enter a valid email address'});
        } else if (!this.state.subjectValid) {
            this.setState({success: false, showAlertBox: true, alertText: 'Please enter a valid subject'});
        } else if (!this.state.messageValid) {
            this.setState({success: false, showAlertBox: true, alertText: 'Please enter a valid message'});
        } else {
            const { name, email, message, subject } = this.state

            await axios.post('https://mail.danjbennett.co.uk', qs.stringify({
                'name': name,
                'email': email,
                'subject': subject,
                'msg': message
            }))
            this.setState({ 
                success: true, 
                showAlertBox: false, 
                alertText: '',
                name: '', 
                nameClass: '',
                nameValid: false,
                email: '',
                emailClass: '',
                emailValid: false,
                subject: '',
                subjectClass: '',
                subjectValid: false,
                message: '',
                messageClass: '',
                messageValid: false,
                isVerified: false,
            });
            document.getElementById("contactForm").reset();
            recaptchaInstance.reset();
        }
    }

    render() {
        return (
            <div className={contactStyle.formWrap}>
                <form id="contactForm" onSubmit={this.handleSubmit}>
                        {/* { 
                            this.state.nameValid ? 
                                <FontAwesomeIcon icon={['fas', 'check-circle']}></FontAwesomeIcon>
                            :
                                <FontAwesomeIcon icon={['fas', 'times-circle']}></FontAwesomeIcon>
                        } */}
                    <label htmlFor="name">Name</label>
                    <input id="name" className={this.state.nameClass} onChange={this.handleChange} name="name" placeholder="Name"/>
    
                    <label htmlFor="email">Email</label>
                    <input id="email" className={this.state.emailClass} onChange={this.handleChange} name="email" placeholder="Email"/>
                    
                    <label htmlFor="subject">Subject</label>
                    <input id="subject" className={this.state.subjectClass} onChange={this.handleChange} name="subject" placeholder="Subject"/>
                    
                    <label htmlFor="phone">Phone</label>
                    <input id="phone" className={this.state.subjectClass} onChange={this.handleChange} name="phone" placeholder="Phone"/>
                    
                    <label htmlFor="message">Message</label>
                    <textarea id="message" className={this.state.messageClass} onChange={this.handleChange} name="message" placeholder="Message"/>
                    <ReCAPTCHA
                        ref={e => recaptchaInstance = e}
                        sitekey={liveSiteKey}
                        onChange={this.verifyCallback}
                    />
                    <button className={contactStyle.button} type="submit">Submit</button>
                </form>
                
                {this.state.showAlertBox ? <div className={ contactStyle.alertBox }><p>{this.state.alertText}</p></div> : null}
                {this.state.success ? <div className={ contactStyle.successBox }><p>Your email has been sent</p></div> : null }
            </div>
        );
    } 
}

export default contactForm;